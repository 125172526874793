import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }

});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

window.addEventListener("load", function() {
  const active = document.getElementsByClassName("elementor-item-active");
  if (active && active.length >= 2) {
    active[0].classList.remove("elementor-item-active");
  }
});
